import axios from '@/axios'
import store from '@/store/store.js'
import appConfig from '../app.config.js'

const actions = {
  add (user_id = null, data = null) {
    if (user_id == null) {
      user_id = `${store.state.AppActiveUser.user_id}`
    }
    const params = new FormData()
    params.set('user_id', user_id)
    params.set('data', data)

    const rimnat_api_call_url = '/user/logUserDiagnostic'
    axios.post(appConfig.apiBaseUrl + rimnat_api_call_url, params)
      .then(() => { 
        console.log('logUserDiagnostic finished') 
      })
      .catch((error)   => { console.log(error) })
  }
}
export default actions