<template>
  <div>
    <vs-alert color="danger" v-if="!loggedIn">{{$t('notLoggedinMessage')}}</vs-alert>
    <div v-if="loggedIn" id="diagnostic">
      <div id="synthesis">

        <div v-if="reportIsFull">
          <vs-alert v-if="!everythingIsOk" color="danger" class="font-bold mb-8" icon-pack="feather" icon="icon-alert-triangle"><strong>{{ $t('diagProblems') }}</strong></vs-alert>
          <!-- <vs-alert v-else color="success" class="font-bold mb-8" icon-pack="feather" icon="icon-check"><strong>{{ $t('diagEverythingIsOk') }}</strong></vs-alert> -->
        </div>

        <!-- ENVIRONEMENT LINE -->
        <h5 class="ml-2">{{ $t('environment') }}</h5>
        <div class="vx-col w-full flex">
          <vx-card class="m-2">
            <h5 class="mb-2">{{ $t('os') }}</h5>
            <div class="justify-between">
              <p>{{osNiceName | uppercase}}</p>
              <p>{{device.platform | uppercase}}</p>
            </div>
          </vx-card>
          <vx-card class="m-2">
            <h5 class="mb-2">{{ $t('browser') }}</h5>
            <div class="flex justify-between flex-wrap">
              <p>{{browserNiceName | uppercase}}</p>
              <vs-alert v-if="!isBrowserSupported" color="danger">{{ $t('diagNotSupportedBrowser') }}</vs-alert>
              <vs-alert v-else-if="!isBrowserUpToDate" color="danger">{{ $t('diagBrowserOutdated') }}</vs-alert>
              <vs-alert v-else color="primary">{{ $t('browserSupportedAndUpToDate') }}</vs-alert>
            </div>
          </vx-card>
          <vx-card class="m-2">
            <h5 class="mb-2">{{ $t('gpu') }}</h5>
            <div class="flex justify-between flex-wrap">
              <p>{{gpuShortName | uppercase}}</p>
              <vs-alert v-if="gpuIsNvidiaOrAmd" color="primary">{{ $t('gpuSupported') }}</vs-alert>
              <div v-else-if="gpuIsIntel">
                <vs-alert color="warning">{{ $t('hybridGpuError') }}. {{ $t('hybridGpuErrorExplained') }}.</vs-alert>
                <h5 class="mt-2 mb-2">{{ $t('please') }} :</h5>

                <vs-button target href="https://rimnat.com/docs/gpu/hybrid-gpu-support/gpu_correction.reg" color="warning" type="filled">
                  {{ $t('downloadAndInstall') }}
                </vs-button>

                <vs-button target href="https://rimnat.com/docs/gpu/hybrid-gpu-support/nvidia-hybrid-gpu-support_french.pdf" color="warning" type="filled" class="mt-2">
                  {{ $t('or') }} : {{ $t('readThisDocument') }}
                </vs-button>

                <div 
                  v-if="typeof this.device.browser != 'undefined' && this.device.browser.name.toLowerCase() != 'edge_chromium'" 
                  class="mt-2 text-warning font-bold">
                  {{ $t('or') }} : {{ $t('useEdgeChromium') }}
                </div>

              </div> 
              <vs-alert v-else color="warning">{{ $t('unknownGpu') }}</vs-alert>
        
            </div>
          </vx-card>
        </div>

        <!-- GRAPHIC LINE -->
        <h5 class="ml-2 mt-4">{{ $t('graphicPerformances') }}</h5>
        <div class="vx-col w-full flex" v-if="gpuBenchmarked">
          <vx-card class="m-2">
            <h5 class="mb-2">{{ $t('gpuScore') }}</h5>
            <div class="flex justify-between flex-wrap w-full">
              <template v-if="gpuBenchmark && gpuBenchmark.score && Number.isInteger(gpuBenchmark.score)">
                <p>{{ $t('g3dScore') }} : <strong>{{gpuBenchmark.score}}</strong></p>
                <vs-progress class="my-2" :height="5" :percent="gpuScorePercent" :color="gpuScorePercent < 50 ? 'danger' : 'primary'"></vs-progress>
              </template>
              <vs-alert v-else color="warning">{{ $t('unknownGpuScore') }}</vs-alert>
            </div>
          </vx-card>
          <vx-card class="m-2" v-if="gpuBenchmark && gpuBenchmark.score && Number.isInteger(gpuBenchmark.score)">
            <h5 class="mb-2">{{ $t('gpuBenchmarkPrecision') }}</h5>
            <div class="flex justify-between flex-wrap">
              <p>{{gpuBenchmark.precision}}%</p>
              <vs-progress class="my-2" :height="5" :percent="gpuBenchmark.precision" :color="gpuBenchmark.precision < 50 ? 'danger' : 'primary'"></vs-progress>
            </div>
          </vx-card>
          <vx-card class="m-2" v-if="gpuBenchmark && gpuBenchmark.score && Number.isInteger(gpuBenchmark.score)">
            <h5 class="mb-2">{{ $t('lod') }}</h5>
            <div class="flex justify-between flex-wrap">
              <p>{{gpuBenchmark.lod}}/5</p>
              <vs-progress class="my-2" :height="5" :percent="lodPercent" :color="gpuBenchmark.lod < 2 ? 'danger' : 'primary'"></vs-progress>
            </div>
          </vx-card>
          <vx-card class="m-2" v-if="typeof this.device.browser != 'undefined'">
            <h5 class="mb-2">{{ $t('webgl') }}</h5>
            <div v-if="webGlIsSupported != null" class="flex justify-between flex-wrap">
              <vs-alert v-if="webGlIsSupported == true" color="primary">{{ $t('webglSupported') }}</vs-alert>
              <div v-else>
                <vs-alert color="danger">{{ $t('diagWebGlNotSupported') }}</vs-alert>
                <h5 class="mt-2 mb-2">{{ $t('please') }} :</h5>
                <li v-if="this.device.browser.name.toLowerCase() == 'edge'">{{ $t('returnWith') }} Edge Chromium, Google Chrome {{$t('or')}} Firefox</li>
                <li v-if="this.device.browser.name.toLowerCase() == 'edge_chromium'">{{ $t('returnWith') }} Chrome {{$t('or')}} Firefox</li>
                <li v-if="this.device.browser.name.toLowerCase() == 'chrome'">{{ $t('returnWith') }} Edge Chromium {{$t('or')}} Firefox</li>
                <li v-if="this.device.browser.name.toLowerCase() == 'firefox'">{{ $t('returnWith') }} Edge Chromium {{$t('or')}} Chrome</li>
                <li>{{ $t('updateGpuDriver') }}</li>
                <li>{{ $t('contactUsAt') }} : contact@rimnat.com</li>
              </div>
            </div>
          </vx-card>
        </div>

        <!-- NETWORK LINE -->
        <h5 class="ml-2 mt-4">{{ $t('networkStatus') }}</h5>
        <div class="vx-col w-full flex">
          <vx-card class="m-2">
            <h5 class="mb-2">{{ $t('yourNetwork') }}</h5>
            <div class="flex justify-between flex-wrap">
              <vs-alert v-if="dangerousNetwork" color="danger">{{ $t('unsafeNetwork') }}</vs-alert>
              <vs-alert v-else color="primary">{{ $t('safeNetwork') }}</vs-alert>
            </div>
          </vx-card>
          <vx-card class="m-2" v-if="typeof this.device.browser != 'undefined' && this.device.browser.name.toLowerCase() != 'firefox'">
            <h5 class="mb-2">{{ $t('connectionSpeed') }}</h5>
            <div v-if="speedTest" class="flex justify-between flex-wrap">
              <vs-alert v-if="speedTest.quality == 0" color="danger">{{ $t('youAreOffline') }}</vs-alert>
              <vs-alert v-else-if="speedTest.quality < 50" color="danger">{{ $t('insuffisentConnection') }}</vs-alert>
              <vs-alert v-else color="primary">{{ $t('goodConnection') }} ({{speedTest.downlink}} Mbps )</vs-alert>
              <vs-progress class="my-2" :height="5" :percent="speedTest.quality" :color="speedTest.quality < 50 ? 'danger' : 'primary'"></vs-progress>
            </div>
          </vx-card>
          <vx-card class="m-2">
            <h5 class="mb-2">{{ $t('browserCaching') }}</h5>
            <div class="flex justify-between flex-wrap">
              <vs-alert v-if="browserCache.enabled == false" color="warning">{{ $t('browserCachingDisabled') }}</vs-alert>
              <vs-alert v-else-if="browserCache.enabled == true" color="primary">{{ $t('browserCachingEnabled') }}</vs-alert>
            </div>
          </vx-card>
          <vx-card class="m-2">
            <h5 class="mb-2">{{ $t('cookies') }}</h5>
            <div class="flex justify-between flex-wrap">
              <vs-alert v-if="cookiesEnabled == false" color="danger">{{ $t('cookiesBlocked') }}</vs-alert>
              <!-- <vs-alert v-else-if="doNotTrackEnabled" color="warning">Do not track mode is activated. To prevent browser caching issues, please disable this option.</vs-alert> -->
              <vs-alert v-else color="primary">{{ $t('cookiesEnabled') }}</vs-alert>
            </div>
          </vx-card>
        </div>
      </div>

      <div class="flex justify-between flex-wrap mt-12">
        <vs-button @click.stop="forceUpdate()" class="w-1/5 mx-2" color="dark" type="line">{{ $t('forceDiagnosticUpdate') }}</vs-button>
        <vs-button @click.stop="fullReportVisible = !fullReportVisible" class="w-1/5 mx-2" color="dark" type="line">{{ $t('displayFullsDiagnostic') }}</vs-button>
        <vs-button @click.stop="copyDiagnostic()" class="w-1/5 mx-2" color="dark" type="line">{{ $t('copyDiagnosticToClipBoard') }}</vs-button>
        <vs-button v-if="from" :to="from" class="w-1/5 mx-2" color="primary" type="filled">{{ $t('Back') }}</vs-button>
        <vs-button v-else-if="redirect" :to="redirect" class="w-1/5 mx-2" color="primary" type="filled">{{ $t('Back') }}</vs-button>
        <vs-button v-else to="/" class="w-1/5 mx-2" color="primary" type="filled">{{ $t('backToHome') }}</vs-button>
      </div>

      <div id="details" v-if="fullReportVisible" class="mt-10 p-10">
        <pre>fullReport: {{fullReport}}</pre>
      </div>

      <renderer v-show="canvasVisible" id="renderer" ref="renderer" :size="{ w: canvasSize.w, h: canvasSize.h }">
        <scene>
          <camera :position="{ x: 2, y: -2, z: 10 }"></camera>
            <mesh name="Cube">
              <geometry type="Box" :args="[1, 1, 1]"></geometry>
            </mesh>
        </scene>
      </renderer>
    </div>
  </div>
</template>

<script>
import Detector from 'detector-js'
import axios from '@/axios.js'
import axiosNoHeaders from 'axios'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import logDiagnostic from '@/logDiagnostic.js'
import logActivity from '@/logActivity.js'

export default {
  components: {
    Detector,
    StatisticsCardLine, 
    logActivity
  },
  data () {
    return {
      mounted: false,

      ipdataUrl: 'https://api.ipdata.co/?api-key=',
      ipdataKey: '140859599e0c9f997c95a47892839a818276fcb277a28771809de3bb',
      ipData: {},
      gl:{},
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      device: {}, 
      gpuBenchmark: null, 
      gpuBenchmarked: false,
      gpuOptimumScoreLevel: 10000,
      canvasVisible: true,
      canvasSize: {
        w:2,
        h:2
      },
      glContext: {
        antialias: true,
        alpha : true,
        depth: true
      },
      glReport: {},
      animate: {}, 
      browsers: {
        chrome: 88,
        firefox: 84,
        edge: 88,
        edge_chromium: 88
      }, 
      browserCache: {},
      cacheCheckFrequency: 2000, 
      cacheReloadsCount: 0,
      cacheReloadAttempts: 1,
      fullReportVisible: false, 
      redirect: null,
      from: null
    }
  },
  methods: {    
    forceUpdate () {
      this.$store.dispatch('flushUserDiagnostic')
      location.replace(this.$route.path)
    },
    // dispatch to store
    storeUserDiag () {
      console.log('storing diagnostic to local storage')
      this.$store.dispatch('updateUserDiagnostic', this.fullReport)
    },
    // log
    logUserDiag () {
      console.log('logging diagnostic')
      const jsonString = JSON.stringify(this.fullReport)
      logDiagnostic.add(parseInt(this.$store.state.AppActiveUser.user_id), jsonString)
    },
    // ROYBON / Copie du lien dans le presse papier
    copyDiagnostic () {
      const thisIns = this
      const jsonString = JSON.stringify(this.fullReport)
      const jsonPretty = JSON.stringify(JSON.parse(jsonString), null, 2)
      thisIns.$copyText(jsonPretty).then(function () {
        thisIns.$vs.notify({
          title: thisIns.$t('success'),
          text: thisIns.$t('TextCopySuccess'),
          color: 'success',
          iconPack: 'feather',
          position: 'top-center',
          icon: 'icon-check-circle'
        })
      })
    },
    checkBrowserCache () {
      if (this.mounted) {
        if (this.browserCache.enabled != true) {
          console.log('checking browser cache...')
          if (this.cacheReloadsCount <= this.cacheReloadAttempts) {
            const res = performance.getEntriesByType('resource').filter(resource => resource.initiatorType == 'script').filter(resource => resource.name.includes('rimnat'))
            // const res = performance.getEntriesByType('resource').filter(resource => resource.initiatorType == 'script')
            if (typeof res != 'undefined' && typeof res[0] != 'undefined') {
              const trSize = res[0].transferSize
              if (trSize <= 250) {
                this.browserCache = {
                  enabled:true, 
                  transferSize: trSize,
                  testedEntity: res[0]
                }
              } else {
                this.browserCache =  {
                  enabled:false, 
                  transferSize: trSize,
                  testedEntity: res[0]
                }
              }
            } else {
              this.browserCache =  {
                enabled:false,
                testedEntity: res[0]
              }
            }
            if (this.cacheReloadsCount < this.cacheReloadAttempts) {
              this.cacheReloadsCount += 1
              if (this.browserCache.enabled != true) {
                this.browserCache.enabled = false
                console.log('cache is disabled --> reloading page to see cache effects')
                const reloadUrl = `${this.$route.path}?reload=${this.cacheReloadsCount}`
                console.log(`redirection to ${reloadUrl}`)
                location.replace(reloadUrl)
              } else {
                const noQuery = this.$route.path
                window.history.replaceState({}, document.title, noQuery)
              }
            }
          }
        } else {
          this.browserCache.enabled = false
          const noQuery = this.$route.path
          window.history.replaceState({}, document.title, noQuery)
        }
      }
    },
    getIpData () {
      const url = `${this.ipdataUrl}${this.ipdataKey}`
      axiosNoHeaders.get(url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') {
            this.ipData = response.data
          }
        })
        .catch((error)   => { console.log(error) })
    },
    getGpuScore () {
      if (this.gpuShortName != null && this.gpuShortName != '') {
        const params = `gpu_name=${this.gpuModel}`
        // const params = 'gpu_name=radeon'
        const rimnat_api_call_url = `${this.api_server_baseurl}/gpu/getGpuBenchmarkV2?${params}`
        axios.get(rimnat_api_call_url, {
        })
          .then((response) => { 
            if (response.data != null && response.data != 'null') {
              this.gpuBenchmark = response.data
              this.gpuBenchmarked = true
            }
          })
          .catch((error)   => { console.log(error) })
      }
    }, 
    getUnmaskedInfo (gl) {
      const unMaskedInfo = {
        renderer: '',
        vendor: ''
      }
      const dbgRenderInfo = gl.getExtension('WEBGL_debug_renderer_info')
      if (dbgRenderInfo != null) {
        unMaskedInfo.renderer = gl.getParameter(dbgRenderInfo.UNMASKED_RENDERER_WEBGL)
        unMaskedInfo.vendor   = gl.getParameter(dbgRenderInfo.UNMASKED_VENDOR_WEBGL)
      }
      return unMaskedInfo
    },
    isPowerOfTwo (n) {
      return (n !== 0) && ((n & (n - 1)) === 0)
    },
    describeRange (value) {
      return `${value[0]}, ${value[1]}]`
    },
    getAngle (gl) {
      const lineWidthRange = this.describeRange(gl.getParameter(gl.ALIASED_LINE_WIDTH_RANGE))
      // Heuristic: ANGLE is only on Windows, not in IE, and not in Edge, and does not implement line width greater than one.
      const angle = ((navigator.platform === 'Win32') || (navigator.platform === 'Win64')) &&
        (gl.getParameter(gl.RENDERER) !== 'Internet Explorer') &&
        (gl.getParameter(gl.RENDERER) !== 'Microsoft Edge') &&
        (lineWidthRange === this.describeRange([1, 1]))
      if (angle) {
        // Heuristic: D3D11 backend does not appear to reserve uniforms like the D3D9 backend, e.g.,
        // D3D11 may have 1024 uniforms per stage, but D3D9 has 254 and 221.
        //
        // We could also test for WEBGL_draw_buffers, but many systems do not have it yet
        // due to driver bugs, etc.
        if (this.isPowerOfTwo(gl.getParameter(gl.MAX_VERTEX_UNIFORM_VECTORS)) && this.isPowerOfTwo(gl.getParameter(gl.MAX_FRAGMENT_UNIFORM_VECTORS))) {
          return 'Yes, D3D11'
        } else {
          return 'Yes, D3D9'
        }
      }
      return 'No'
    },
    getMaxColorBuffers (gl) {
      let maxColorBuffers = 1
      const ext = gl.getExtension('WEBGL_draw_buffers')
      if (ext != null) maxColorBuffers = gl.getParameter(ext.MAX_DRAW_BUFFERS_WEBGL)
      return maxColorBuffers
    },
    getMaxAnisotropy (gl) {
      const e = gl.getExtension('EXT_texture_filter_anisotropic')
        || gl.getExtension('WEBKIT_EXT_texture_filter_anisotropic')
        || gl.getExtension('MOZ_EXT_texture_filter_anisotropic')

      if (e) {
        let max = gl.getParameter(e.MAX_TEXTURE_MAX_ANISOTROPY_EXT)
        // See Canary bug: https://code.google.com/p/chromium/issues/detail?id=117450
        if (max === 0) {
          max = 2
        }
        return max
      }
      return 'n/a'
    },
    getWebGL2Status (gl, contextName = 'webgl2') {
      const webgl2Names = [
        'copyBufferSubData',
        'getBufferSubData',
        'blitFramebuffer',
        'framebufferTextureLayer',
        'getInternalformatParameter',
        'invalidateFramebuffer',
        'invalidateSubFramebuffer',
        'readBuffer',
        'renderbufferStorageMultisample',
        'texStorage2D',
        'texStorage3D',
        'texImage3D',
        'texSubImage3D',
        'copyTexSubImage3D',
        'compressedTexImage3D',
        'compressedTexSubImage3D',
        'getFragDataLocation',
        'uniform1ui',
        'uniform2ui',
        'uniform3ui',
        'uniform4ui',
        'uniform1uiv',
        'uniform2uiv',
        'uniform3uiv',
        'uniform4uiv',
        'uniformMatrix2x3fv',
        'uniformMatrix3x2fv',
        'uniformMatrix2x4fv',
        'uniformMatrix4x2fv',
        'uniformMatrix3x4fv',
        'uniformMatrix4x3fv',
        'vertexAttribI4i',
        'vertexAttribI4iv',
        'vertexAttribI4ui',
        'vertexAttribI4uiv',
        'vertexAttribIPointer',
        'vertexAttribDivisor',
        'drawArraysInstanced',
        'drawElementsInstanced',
        'drawRangeElements',
        'drawBuffers',
        'clearBufferiv',
        'clearBufferuiv',
        'clearBufferfv',
        'clearBufferfi',
        'createQuery',
        'deleteQuery',
        'isQuery',
        'beginQuery',
        'endQuery',
        'getQuery',
        'getQueryParameter',
        'createSampler',
        'deleteSampler',
        'isSampler',
        'bindSampler',
        'samplerParameteri',
        'samplerParameterf',
        'getSamplerParameter',
        'fenceSync',
        'isSync',
        'deleteSync',
        'clientWaitSync',
        'waitSync',
        'getSyncParameter',
        'createTransformFeedback',
        'deleteTransformFeedback',
        'isTransformFeedback',
        'bindTransformFeedback',
        'beginTransformFeedback',
        'endTransformFeedback',
        'transformFeedbackVaryings',
        'getTransformFeedbackVarying',
        'pauseTransformFeedback',
        'resumeTransformFeedback',
        'bindBufferBase',
        'bindBufferRange',
        'getIndexedParameter',
        'getUniformIndices',
        'getActiveUniforms',
        'getUniformBlockIndex',
        'getActiveUniformBlockParameter',
        'getActiveUniformBlockName',
        'uniformBlockBinding',
        'createVertexArray',
        'deleteVertexArray',
        'isVertexArray',
        'bindVertexArray'
      ]

      const webgl2 = (contextName.indexOf('webgl2') !== -1)

      const functions = []
      let totalImplemented = 0
      const length = webgl2Names.length

      if (webgl2) {
        for (let i = 0; i < length; ++i) {
          const name = webgl2Names[i]
          let className = 'extension'
          if (webgl2 && gl[name]) {
            ++totalImplemented
          } else {
            className += ' unsupported'
          }
          functions.push({ name: name, className: className })
        }
      }

      return {
        status : webgl2 ? `${totalImplemented} of ${length} new functions implemented.` : 'webgl2 and experimental-webgl2 contexts not available.',
        functions : functions
      }
    },

    webGlReport () {
      if (typeof this.$refs.renderer != 'undefined') {
        const gl = this.$refs.renderer.global.rendererDom.getContext('webgl2', this.glContext)
        this.gl = {
          glVersion: gl.getParameter(gl.VERSION),
          shadingLanguageVersion: gl.getParameter(gl.SHADING_LANGUAGE_VERSION),
          vendor: gl.getParameter(gl.VENDOR),
          renderer: gl.getParameter(gl.RENDERER),
          unMaskedVendor: this.getUnmaskedInfo(gl).vendor,
          unMaskedRenderer: this.getUnmaskedInfo(gl).renderer,
          antialias:  gl.getContextAttributes().antialias ? 'Available' : 'Not available',
          angle: this.getAngle(gl),
          maxColorBuffers: this.getMaxColorBuffers(gl),
          redBits: gl.getParameter(gl.RED_BITS),
          greenBits: gl.getParameter(gl.GREEN_BITS),
          blueBits: gl.getParameter(gl.BLUE_BITS),
          alphaBits: gl.getParameter(gl.ALPHA_BITS),
          depthBits: gl.getParameter(gl.DEPTH_BITS),
          stencilBits: gl.getParameter(gl.STENCIL_BITS),
          maxRenderBufferSize: gl.getParameter(gl.MAX_RENDERBUFFER_SIZE),
          maxCombinedTextureImageUnits: gl.getParameter(gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS),
          maxCubeMapTextureSize: gl.getParameter(gl.MAX_CUBE_MAP_TEXTURE_SIZE),
          maxFragmentUniformVectors: gl.getParameter(gl.MAX_FRAGMENT_UNIFORM_VECTORS),
          maxTextureImageUnits: gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS),
          maxTextureSize: gl.getParameter(gl.MAX_TEXTURE_SIZE),
          maxVaryingVectors: gl.getParameter(gl.MAX_VARYING_VECTORS),
          maxVertexAttributes: gl.getParameter(gl.MAX_VERTEX_ATTRIBS),
          maxVertexTextureImageUnits: gl.getParameter(gl.MAX_VERTEX_TEXTURE_IMAGE_UNITS),
          maxVertexUniformVectors: gl.getParameter(gl.MAX_VERTEX_UNIFORM_VECTORS),
          aliasedLineWidthRange: this.describeRange(gl.getParameter(gl.ALIASED_LINE_WIDTH_RANGE)),
          aliasedPointSizeRange: this.describeRange(gl.getParameter(gl.ALIASED_POINT_SIZE_RANGE)),
          maxViewportDimensions: this.describeRange(gl.getParameter(gl.MAX_VIEWPORT_DIMS)),
          maxAnisotropy: this.getMaxAnisotropy(gl),
  
          extensions: gl.getSupportedExtensions(),
  
          webgl2Status : this.getWebGL2Status(gl).status,
          webgl2Functions : this.getWebGL2Status(gl).functions
        }
      }
    }
  },
 
  computed: {
    loggedIn () {
      return this.$store.state.AppActiveUser.user_id !== null && parseInt(this.$store.state.AppActiveUser.user_id) !== '0'
    }, 
    reportIsFull () {
      return this.mounted && this.browserCache && this.browserCache.enabled != null 
    },
    computedValues () {
      return {
        gpuFullName: this.gpuFullName,
        gpuLongName: this.gpuLongName,
        gpuShortName: this.gpuShortName,
        gpuModel: this.gpuModel,
        isEdgeChromium: this.isEdgeChromium,
        gpuIsNvidiaOrAmd: this.gpuIsNvidiaOrAmd,
        gpuIsIntel: this.gpuIsIntel,
        webGlIsSupported: this.webGlIsSupported, 
        speedTest: this.speedTest || null
      }
    },
    fullReport () {
      if (this.mounted && typeof this.device != 'undefined' && this.device && typeof this.gpuBenchmark != 'undefined' && this.gpuBenchmark) {
        return {
          computedValues: this.computedValues || null,
          summaryReport: this.summaryReport || null,
          ipData: this.ipData || null,
          gpuBenchmark: this.gpuBenchmark || null,
          device: this.device || null,
          gl: this.gl || null
        }
      } else {
        return null
      }
    },
    summaryReport () {
      if (this.mounted && typeof this.device != 'undefined' && this.device && typeof this.gpuBenchmark != 'undefined' && this.gpuBenchmark) {
        return {
          os: this.osNiceName || null,
          browser: this.browserNiceName || null,
          isBrowserSupported: this.isBrowserSupported || null,
          isBrowserUpToDate: this.isBrowserUpToDate || null,
          gpuFullName: this.gpuFullName || null,
          gpuLongName: this.gpuLongName || null,
          gpuShortName: this.gpuShortName || null,
          gpuModel: this.gpuModel || null,
          gpuScore: this.gpuBenchmark.score || null,
          webGL: this.webGlIsSupported,
          cookies: this.cookiesEnabled,
          browserCache: this.browserCache.enabled || null,
          doNotTrack: this.doNotTrackEnabled
        }
      } else {
        return null
      }
    },
    doNotTrackEnabled () {
      return navigator.doNotTrack || navigator.doNotTrack == true || navigator.doNotTrack == '1' 
    },
    cookiesEnabled () {
      return navigator.cookieEnabled
    },
    isEdgeChromium () {
      const value = navigator.userAgent.toLowerCase()
      return (value.includes('edg/') && value.includes('chrom'))
    },
    speedTest () {
      if (typeof this.device.browser != 'undefined' && this.isBrowserSupported) {
        const browserName = this.device.browser.name.toLowerCase()
        if (browserName != 'firefox') {
          let quality = 0
          const conn = navigator.connection || navigator.mozConnection || navigator.webkitConnection
          const downlink = parseFloat(conn.downlink)
          const effectiveType = conn.effectiveType
          const online = navigator.onLine
          if (online == false || downlink == 0) {
            quality = 0
          } else if (effectiveType == '2g') {
            quality = 5
          } else if (effectiveType == '3g') {
            quality = 15
          } else if (effectiveType == '4g' && downlink > 5) {
            quality = 90 
          } else if (effectiveType == '4g' && downlink > 4) {
            quality = 70 
          } else if (effectiveType == '4g' && downlink > 3) {
            quality = 50 
          } else {
            quality = 30 
          }
          return {
            quality: quality,
            downlink: downlink
          }
        }
      }
    },
    webGlIsSupported () {
      let supported = null
      if (typeof this.gl.glVersion != 'undefined') {
        supported = true
        if (!this.gl.glVersion.toLowerCase().includes('webgl')) supported = false
        if (!this.gl.shadingLanguageVersion.toLowerCase().includes('gl')) supported = false
        if (parseInt(this.gl.maxAnisotropy) < 16) supported = false
        if (this.gl.antialias.toLowerCase() != 'available') supported = false
        if (this.gl.webgl2Status != '88 of 88 new functions implemented.') supported = false
      } else {
        supported = false
      }
      return supported
    },
    isBrowserSupported () {
      if (typeof this.device.browser != 'undefined') {
        const browserName = this.device.browser.name.toLowerCase()
        let result = false
        result = this.browsers.hasOwnProperty(browserName)
        return result
      }
    },
    isBrowserUpToDate () {
      if (typeof this.device.browser != 'undefined' && this.isBrowserSupported) {
        const browserName = this.device.browser.name.toLowerCase()
        const browserVersion = parseInt(this.device.browser.version.split('.')[0])
        let result = false
        if (browserName == 'chrome') result = browserVersion >= this.browsers.chrome
        if (browserName == 'firefox') result = browserVersion >= this.browsers.firefox
        if (browserName == 'edge') result = browserVersion >= this.browsers.edge
        if (browserName == 'edge_chromium') result = browserVersion >= this.browsers.edge_chromium
        return result
      }
    },
    dangerousNetwork () {
      if (typeof this.ipData != 'undefined' && typeof this.ipData.threat != 'undefined') {
        if (this.ipData.threat.is_tor) return true
        if (this.ipData.threat.is_proxy) return true
        if (this.ipData.threat.is_anonymous) return true
        if (this.ipData.threat.is_known_attacker) return true
        if (this.ipData.threat.is_known_abuser) return true
        if (this.ipData.threat.is_threat) return true
        if (this.ipData.threat.is_bogon) return true
      }
    },
    gpuScorePercent () {
      if (typeof this.gpuBenchmark != 'undefined') {
        if (parseInt(this.gpuBenchmark.score) > this.gpuOptimumScoreLevel) {
          return 100
        } else {
          return parseInt(this.gpuBenchmark.score / this.gpuOptimumScoreLevel * 100)
        }
      }
    },
    lodPercent () {
      if (typeof this.gpuBenchmark != 'undefined') {
        return parseInt(this.gpuBenchmark.lod / 5 * 100)
      }
    },
    gpuFullName () {
      if (typeof this.device.gpu != 'undefined') {
        let gpuModel = this.device.gpu['model'].trim().toLowerCase()
        // gpuModel = 'intel mesa intel uhd graphics 620 (kbl gt2'  // TODO : keep disabled this test line
        // gpuModel = 'nvidia geforce gtx 1070'  // TODO : keep disabled this test line
        // gpuModel = 'angle (nvidia corporation, geforce rtx 2060 super/pcie/sse2, opengl 4.5 core)'  // TODO : keep disabled this test line
        // gpuModel = 'angle (intel(r) uhd graphics direct3d11 vs_5_0_ps_5_0)'  // TODO : keep disabled this test line
        // gpuModel = 'mesa dri intel(r) hd graphics 4000 (ivb gt2)'            // TODO : keep disabled this test line
        // gpuModel = 'mali t-830'                                              // TODO : keep disabled this test line
        gpuModel = gpuModel.replace('(r)', '')
        gpuModel = gpuModel.replace('(c)', '')
        gpuModel = gpuModel.replace('(ivb gt2)', '')
        gpuModel = gpuModel.replace('kbl', '')
        gpuModel = gpuModel.replace('gt2', '')
        gpuModel = gpuModel.replace('corporation', '')
        gpuModel = gpuModel.replace('corp', '')
        gpuModel = gpuModel.replace('sse2', '')
        gpuModel = gpuModel.replace('pcie', '')
        gpuModel = gpuModel.replace('super', '')
        gpuModel = gpuModel.replace('.', '')
        gpuModel = gpuModel.replace(',', '')
        gpuModel = gpuModel.replace(',', '')
        gpuModel = gpuModel.replace(',', '')
        gpuModel = gpuModel.replace('/', '')
        gpuModel = gpuModel.replace('/', '')
        // gpuModel = gpuModel.replace('(', '')
        // gpuModel = gpuModel.replace(')', '')
        gpuModel = gpuModel.replace('  ', ' ')
        gpuModel = gpuModel.replace('  ', ' ')
        return gpuModel
      } else {
        return null
      }
    },
    gpuLongName () {
      if (this.gpuFullName) {
        if (this.gpuFullName.includes('angle')) {
          const regExp = /\(([^)]+)\)/
          const matches = regExp.exec(this.gpuFullName)
          if (matches != null && matches[1] != null) {
            return matches[1].trim().toLowerCase()
          } else {
            return this.gpuFullName.trim().toLowerCase()
          }
        } else {
          return this.gpuFullName.trim().toLowerCase()
        }
      } else {
        return null
      }
    },
    gpuShortName () {
      if (this.gpuLongName != null) {
        let value = this.gpuLongName.toLowerCase()
        if (value.includes('direct3d')) {
          value = value.replace('direct', ';').split(';')[0].trim().toLowerCase()
        }
        if (value.includes('open')) {
          value = value.replace('open', ';').split(';')[0].trim().toLowerCase()
        }
        return value
      } else {
        return null
      }
    },
    gpuModel () {
      if (this.gpuShortName != null) {
        const value = this.gpuShortName.toLowerCase()
        if (value.includes('nvidia ')) {
          return value.replace('nvidia ', '').trim().toLowerCase()
        } else if (value.includes('amd ')) {
          return value.replace('amd ', '').trim().toLowerCase()
        } else {
          return value.trim().toLowerCase()
        }
      } else {
        return null
      }
    },
    browserNiceName () {
      if (typeof this.device.browser != 'undefined') {
        return `${this.device.browser.name.toLowerCase()} ${this.device.browser.version.split('.')[0]}.${this.device.browser.version.split('.')[1]}`
      }
    },
    osNiceName () {
      if (typeof this.device.os != 'undefined') {
        return `${this.device.os.name.toLowerCase()} ${this.device.os.versionName}`
      }
    },
    gpuIsNvidiaOrAmd () {
      if (this.gpuFullName != null) {
        return this.gpuFullName.includes('nvidia') || this.gpuFullName.includes('amd')
      }
    },
    gpuIsIntel () {
      if (this.gpuFullName != null) {
        return this.gpuFullName.includes('intel')
      }
    },
    everythingIsOk () {
      if (this.reportIsFull) {
        let result = true
        if (this.fullReport != null) {
          if (!this.fullReport.summaryReport.isBrowserSupported) result = false
          if (!this.fullReport.summaryReport.isBrowserUpToDate) result = false
          if (!this.fullReport.summaryReport.webGL) result = false
          if (!this.fullReport.summaryReport.cookies) result = false
        }
        return result
      }
    }
  },

  mounted () {
    if (this.loggedIn) {
      const userAgent = navigator.userAgent.toLowerCase()
      const detector = new Detector()
      if (detector.browser.name.toLowerCase() == 'chrome' && userAgent.includes('edg/')) {
        detector.browser.name = 'edge_chromium'
      }
      this.device = detector
      this.getIpData()
      logActivity.add('diagnostic_page_access', 'success')
    }
    this.mounted = true
  },
  created () {
    if (this.$route.query.reload && parseInt(this.$route.query.reload) > 0) this.cacheReloadsCount = parseInt(this.$route.query.reload)
    if (this.$route.query.redirect && this.$route.query.redirect != '') this.redirect = this.$route.query.redirect
    if (this.$route.query.from && this.$route.query.from != '') this.from = this.$route.query.from
  },

  watch: {
    reportIsFull () {
      if (this.loggedIn && this.mounted && this.reportIsFull == true && this.fullReport != null && this.fullReport.summaryReport != null && this.fullReport.ipData != null && this.fullReport.gpuBenchmark != null && this.fullReport.device != null && this.fullReport.gl != null) {
        // checking if diag is yet in store
        // if (typeof this.$store.state.userDiagnostic == 'undefined' || !this.$store.state.userDiagnostic || typeof this.$store.state.userDiagnostic.summaryReport == 'undefined' || typeof this.$store.state.userDiagnostic.summaryReport.os == 'undefined') {
        this.logUserDiag()
        this.storeUserDiag()
        // } else {
        //   const checkedValue = this.$store.state.userDiagnostic.summaryReport.os
        //   if (!checkedValue || checkedValue == null || checkedValue == '') {
        //     this.logUserDiag()
        //     this.storeUserDiag()
        //   } else {
        //     console.log('diagnostic is yet in store')
        //   }
        // }
        this.$vs.loading.close()
        // redirection si aucun problème
        if (this.everythingIsOk) {
          if (this.redirect && this.redirect != '') this.$router.push(this.redirect).catch(() => {})
        } else {
          this.$vs.notify({
            color:'danger',
            position:'top-center',
            title:this.$t('diagProblems')
          })  
        }
      }
    },
    gpuShortName () {
      this.getGpuScore()
    },
    mounted () {
      if (this.mounted && this.loggedIn) {
        this.webGlReport()
        const self = this
        setTimeout(function () { 
          self.checkBrowserCache()
        }, self.cacheCheckFrequency)
        setTimeout(function () { 
          const noQuery = self.$route.path
          window.history.replaceState({}, document.title, noQuery)
        }, self.cacheCheckFrequency * 2)
        // this.timer = setInterval(this.checkBrowserCache, this.cacheCheckFrequency)
        this.$vs.loading({
          type: 'sound', 
          color: 'white',
          background: 'rgba(0, 0, 0, 0.9)'
        })
      }
    }

  }
}

</script>
<style lang="scss">
</style>
